import { NavLink, useNavigate } from 'react-router-dom';
import unspecifiedperson from "../../assets/images/unspecifiedperson.png";
import { menuItemsData } from '../../menuItemsData';
import MenuItems from "./MenuItems";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { GetOrPostUser} from '../../MindBraceApi';
import MediaQuery from 'react-responsive';
import HamburgerMenuItems from './HamburgerMenuItems';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import { extension_link } from '../../links';

const Navbar = () => {
    const { instance, accounts } = useMsal();

    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

    const history = useNavigate();
    
    const hamburger_clicked = () => {
        setShowHamburgerMenu(!showHamburgerMenu);
    }

    const close_hamburger = (event: React.MouseEvent<HTMLDivElement>) => {
        setShowHamburgerMenu(false);
    }

    const Login = async () => {
        try {
            instance.loginRedirect();
        } catch (error) {
            console.error(error);
        }
    }

    const Logout = async () => {
        try {
            instance.logoutRedirect();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if(accounts[0] != null) {
            const claims = accounts[0]?.idTokenClaims;
            const email = claims?.["emails"]?.[0];
            GetOrPostUser(email!)
        }
    }, [accounts])

    useEffect(() => {
        if (showHamburgerMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showHamburgerMenu]);


    return(
        <>
            {showHamburgerMenu && <div className="overlay" onClick={close_hamburger}></div>}
            <MediaQuery maxWidth={768}>
                <div className="hamburger-menu-close-button-container">
                    <button className="hamburger-menu-open-button" onClick={hamburger_clicked}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
                <div className={`hamburger-menu ${showHamburgerMenu ? 'open' : ''}`}>
                            <div className="hamburger-menu-close-button-container">
                                <button className="hamburger-menu-close-button" onClick={hamburger_clicked}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </div>
                            <nav className="hamburger-navbar">
                                <ul className="hamburger-menus">
                                    {menuItemsData.map((menu, index) => {
                                        return (<div onClick={close_hamburger} key={index}><HamburgerMenuItems items={menu}/></div>);
                                    })}
                                </ul>
                            </nav>
                        </div>
            </MediaQuery>
            <MediaQuery minWidth={769}>
                <nav className="desktop-navbar">
                    <ul className="menus">
                        {menuItemsData.map((menu, index) => {
                            return (<MenuItems items={menu} key={index} />);
                        })}
                    </ul>
                </nav>
            </MediaQuery>
        </>
    );
};

export default Navbar;