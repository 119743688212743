
import { useNavigate } from 'react-router-dom';
import React from "react";
import {TeamMembers} from "../components/teamMembers/TeamMembers";
import { extension_link } from '../links';

export function Home() {

    const history = useNavigate();

    return (
        <div>
            <div className="home-page-title-container">
                <div className="home-title-div">
                    <span className="normal-title-text">Helping you learn the&nbsp;</span>
                    <span className="highlighted-title-text">important stuff&nbsp;</span>
                </div>
            </div>
            <div className="pt-4 home-page-content">
                <h2 className="home-page-content-title">About Us</h2>
                <p>
                    Welcome to Elixo Technologies, where we are dedicated to igniting curiosity and facilitating continuous learning through seamlessly integrated tools that enrich your everyday experiences.<br/><br/>
                    We envision a world where learning knows no bounds, where every individual has unfettered access to high-quality education and the tools to unlock their full potential. Through innovative technology and unwavering commitment, we strive to empower both learners and educators alike, bridging the gap between information and understanding.<br/><br/>
                    But our journey doesn't end here. We are relentlessly pushing boundaries, exploring new frontiers in education technology to offer a comprehensive suite of tools that revolutionize the way we learn and teach. With Elixo Technologies by your side, the pursuit of knowledge becomes an exhilarating adventure, enriching lives and shaping futures.<br/><br/>
                    Join us as we embark on this transformative journey towards a world where learning is not just a destination, but a lifelong odyssey of discovery and growth. Together, let's unlock the boundless potential within each and every one of us.
                </p>

                <TeamMembers />
            </div>
        </div>
    );
}